import { operations } from "./operations";

export function zoomTilStart() {
  const iframe = document.getElementById("viewer");
  iframe.contentWindow.postMessage(
    {
      id: "geocortex-web",
      type: "run-command",
      arguments: "map.zoom-to-initial-viewpoint",
    },
    // WARNING: It's critical to specify an explicit target
    // origin instead of "*" to avoid sending messages to a
    // malicious origin. See
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage.
    "*"
  );
}
export function slettMarkeringer() {
  const iframe = document.getElementById("viewer");
  iframe.contentWindow.postMessage(
    {
      id: "geocortex-web",
      type: "run-command",
      arguments: "location-marker.clear",
    },
    // WARNING: It's critical to specify an explicit target
    // origin instead of "*" to avoid sending messages to a
    // malicious origin. See
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage.
    "*"
  );
}
export function visPunkt() {
  const iframe = document.getElementById("viewer");
  iframe.contentWindow.postMessage(
    {
      id: "geocortex-web",
      type: "run-command",
      arguments: [
        {
          name: "fagsysystemmodul-service.runoperation",
          arguments: {
            operation: operations.showpoint,
            args: {
              x: Number(document.getElementById("pointX").value),
              y: Number(document.getElementById("pointY").value),
            },
          },
        },
      ],
    },
    // WARNING: It's critical to specify an explicit target
    // origin instead of "*" to avoid sending messages to a
    // malicious origin. See
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage.
    "*"
  );
}
export function hentPunkt() {
  const iframe = document.getElementById("viewer");
  iframe.contentWindow.postMessage(
    {
      id: "geocortex-web",
      type: "run-command",
      arguments: [
        {
          name: "fagsysystemmodul-service.runoperation",
          arguments: {
            operation: operations.getpoint,
          },
        },
      ],
    },
    // WARNING: It's critical to specify an explicit target
    // origin instead of "*" to avoid sending messages to a
    // malicious origin. See
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage.
    "*"
  );
}
