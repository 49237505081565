import React from "react";

const src =
"https://apps.geocortex.com/webviewer/?app=b6881c9294de4164848fd11a4af4345a"; //"http://localhost:3000";

export function getIframe(dimensions) {
  return <iframe
    title="Origo"
    id="viewer"
    allow="geolocation *"
    src={src}
    // frameBorder="0"
    style={{
      overflow: "hidden",
      height: dimensions.height,
      width: dimensions.width,
      display: "block",
      position: "relative",
    }} />;
}
