import React from "react";
import PuffLoader from "react-spinners/PuffLoader";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

export function getModal(loading) {
  return <Modal
    open={loading}
    closeAfterTransition
    style={{
      pointerEvents: "none",
    }}
  >
    <>
      <Fade>
        <>
          <PuffLoader
            color="#36d7b7"
            size={240}
            speedMultiplier={0.38}
            cssOverride={{
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
              display: "flex",
              top: "33%",
            }} />
        </>
      </Fade>
    </>
  </Modal>;
}
