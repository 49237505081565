import React from "react";
import Button from "@mui/material/Button";
import {
  zoomTilStart,
  hentPunkt,
  visPunkt,
  slettMarkeringer
} from "./geocortexFunctions";

export function getSlettMarkeringerButton() {
  return getButton("Slett Markeringer", slettMarkeringer);
}
export function getVisPunktButton() {
  return getButton("Marker Punkt", visPunkt);
}

export function getHentPunktButton() {
  return getButton("Hent Punkt", hentPunkt);
}
export function getZoomTilStartButton() {
  return getButton("Zoom til start", zoomTilStart);
}
function getButton(text, func) {
  return <Button variant="text" onClick={func}>{text}</Button>;
}

