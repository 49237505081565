import React from "react";
import TextField from "@mui/material/TextField";

export function getTextfield(fieldId, label) {
  return <TextField
    id={fieldId}
    label={label}
    variant="filled"
    InputLabelProps={{
      shrink: true,
    }} />;
}
export function setValueById(id, value) {
  document.getElementById(id).value = value;
}

export function getEventValue(event) {
  // Value is a json string so we need to parse it if it exists
  return event.data?.value
    ? JSON.parse(event.data.value)
    : undefined;
}

export function getAlertMessage(value) {
  const plurality = value.length > 1 ? "er" : "";
  
  return "Hentet " + value.length + " objekt" + plurality + ". Se konsoll for detaljer";
}
