import React from "react";

import { operations } from "./operations";
import { getEventValue, setValueById, getTextfield, getAlertMessage } from "./helperFunctions";
import { getModal } from "./getModal";
import { getIframe } from "./getIframe";
import { getZoomTilStartButton, getHentPunktButton, getVisPunktButton, getSlettMarkeringerButton } from "./buttons";
import { elementIds } from "./elementIds";

function App() {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight - 56,
    width: window.innerWidth,
  });

  const [loading, setLoading] = React.useState(true);

  const allowedOrigins = ["http://localhost", "https://apps.geocortex.com/"];

  React.useEffect(() => {
    // Handle resizing of window
    function handleResize() {
      setDimensions({
        height: window.innerHeight - 56,
        width: window.innerWidth,
      });
    }

    // Main function for recieving data from iframe
    function handleMessage(event) {
      if (!allowedOrigins.indexOf(event.origin) < 0) {
        return;
      }
     
      const value = getEventValue(event);

      switch (event.data.operation) {
        case operations.getpoint:
          setValueById(elementIds.pointX, value.geometry.x);
          setValueById(elementIds.pointY, value.geometry.y);
          break;

        case operations.search:
          console.log({searchResult: value});
          alert(getAlertMessage(value))
          break;

        case operations.mapLoaded:
          console.log("App reports map loaded.");
          setLoading(false);
          break;

        case operations.alive:
          console.log("App reports alive status.");
          break;

        default:
          break;
      }
    }

    window.addEventListener("resize", handleResize);
    window.addEventListener("message", handleMessage, false);

    return (_) => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("message", handleMessage);
    };
  });

  return (
    <div>
      <div>
        {getZoomTilStartButton()}

        {getHentPunktButton()}

        {getTextfield(elementIds.pointX, "X")}
        {getTextfield(elementIds.pointY, "Y")}

        {getVisPunktButton()}

        {getSlettMarkeringerButton()}
      </div>
      <div>
        {getModal(loading)}

        {getIframe(dimensions)}
      </div>
    </div>
  );
}

export default App;
