
export const operations = {
  // // Not implemented
  // getNeighbours: "getNeighbours",
  // getpointWithMenu: "getpointWithMenu",
  // getpointCoordinates: "getpointCoordinates",
  // getpointCoordinatesWithMenu: "getpointCoordinatesWithMenu",
  // getBuildings: "getBuildings",
  // view: "view",
  
  // Implemented
  showpoint: "showpoint",
  getpoint: "getpoint",
  search: "search",

  // The application fires events with these operations to signify success
  alive: "alive",
  mapLoaded: "mapLoaded"
};
